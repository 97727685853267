import React, {useEffect} from 'react';
import AppRowContainer from '../../../../@crema/core/AppRowContainer';
import {Avatar, Col} from 'antd';
import './index.style.less';
import AppPageMetadata from '../../../../@crema/core/AppPageMetadata';
import {useMatch} from 'react-router-dom';
import {
  useLeader,
  useLeaderActions,
} from '../../../../utility/hooks/LeaderHooks';
import AppAnimateGroup from '../../../../@crema/core/AppAnimateGroup';
import {AiFillCheckCircle} from 'react-icons/ai';
import MarketingCampaign from './MarketingCampaign';
import moment from 'moment';
import {TiStarFullOutline} from 'react-icons/ti';

// eslint-disable-next-line no-unused-vars
const {REACT_APP_BLOB_STORAGE_PROFILE_ENDPOINT} = process.env;

const Portfolio = () => {
  const match = useMatch('people/leaders/:userName/portfolio');
  const userName = match ? match.params.userName : undefined;
  const {userTradingGroupPricingPlan} = useLeader();
  const userTradingGroup = userTradingGroupPricingPlan
    ? userTradingGroupPricingPlan.userTradingGroup
    : null;
  const user = userTradingGroup ? userTradingGroup.user : null;
  const marketInstrumentStatistics =
    userTradingGroup?.tradingGroupStatistics?.marketInstrumentStatistics;

  const {fetchLeader} = useLeaderActions();

  const name = user?.profile
    ? `${user.profile.firstName} ${user.profile.lastName}`
    : user?.name;

  const city = userTradingGroupPricingPlan?.userTradingGroup?.user?.address
    ?.city
    ? userTradingGroupPricingPlan.userTradingGroup.user.address.city
    : null;
  const country = userTradingGroupPricingPlan?.userTradingGroup?.user?.address
    ?.country?.name
    ? userTradingGroupPricingPlan.userTradingGroup.user.address.country.name
    : null;

  const address = city && country ? `${city}, ${country}` : null;

  const image = user?.image
    ? `${REACT_APP_BLOB_STORAGE_PROFILE_ENDPOINT}/${user.image}`
    : '/assets/images/placeholder.jpg';

  useEffect(() => {
    if (userName) {
      fetchLeader(userName);
    }
  }, []);

  return (
    <>
      <AppPageMetadata title='Leader Portfolio' />
      <AppRowContainer>
        <Col xs={24}>
          <h2>Portfolio</h2>
          <div className={'last-update'}>
            <span className={'last-update__label'}>Last Update: </span>
            <span>{moment().format('MM/DD/YYYY')}</span>
          </div>
        </Col>
        <Col className={'section-col'} xs={24} lg={24}>
          <AppAnimateGroup type='bottom' animateStyle={{height: 'auto'}}>
            <div className='video-call-user-info'>
              <div className='video-call-user'>
                <Avatar src={image} alt='User' />
                <span className='video-call-user-status'>
                  <AiFillCheckCircle />
                </span>
              </div>
              <div className={'video-call-user-details'}>
                <h3>{name} (@{user?.name}) <TiStarFullOutline /></h3>
                {address && <p>{address}</p>}
              </div>
            </div>
          </AppAnimateGroup>
        </Col>
        {marketInstrumentStatistics && (
          <Col className={'section-col'} xs={24} lg={24}>
            <MarketingCampaign marketingCampaign={marketInstrumentStatistics} />
          </Col>
        )}
      </AppRowContainer>
    </>
  );
};

export default Portfolio;
