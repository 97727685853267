import React, {createContext, useContext, useEffect, useState} from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {LeaderActionsContextType, LeaderContextType} from './types';
import {useJWTAuth} from '../../@crema/services/auth/jwt-auth/JWTAuthProvider';
import {UserTradingGroupPricingPlanApi} from '../../utility/types/user';
import {UserPaymentMethodApi} from '../payment/PaymentInformationProvider';

const defaultLeaderContext = {
  userTradingGroupPricingPlan: null,
  subscriptionStopFollowReasons: [],
  followerSubscription: null,
  leaderUserExperience: null,
  isFollowed: false,
  loading: false,
  userPaymentMethods: [],
  defaultPaymentMethodForPayment: null,
  defaultPaymentMethodForIncome: null,
};

const LeaderContext = createContext<LeaderContextType>(defaultLeaderContext);

const LeaderActionsContext = createContext<LeaderActionsContextType>({
  fetchLeader: () => {},
  checkFollowerSubscription: () => {},
  fetchIsFollower: () => {},
  setFollowed: () => {},
  stopFollow: () => {},
  setFollowerSubscription: () => {},
  checkSubscriptionPossibility: () => {},
});

export const useLeaderContext = () => useContext(LeaderContext);
export const useLeaderActionsContext = () => useContext(LeaderActionsContext);

const LeaderProvider = ({children}: any) => {
  const {user} = useJWTAuth();
  const [userTradingGroupPricingPlan, setUserTradingGroupPricingPlan] =
    useState<UserTradingGroupPricingPlanApi | null>(
      defaultLeaderContext.userTradingGroupPricingPlan,
    );
  const [loading, setLoading] = useState(defaultLeaderContext.loading);
  const [leaderUserExperience, setLeaderUserExperience] = useState(
    defaultLeaderContext.leaderUserExperience,
  );
  const [isFollowed, setFollowed] = useState(defaultLeaderContext.isFollowed);
  const [followerSubscription, setFollowerSubscription] = useState(
    defaultLeaderContext.followerSubscription,
  );
  const [subscriptionStopFollowReasons, setSubscriptionStopFollowReasons] =
    useState(defaultLeaderContext.subscriptionStopFollowReasons);
  const [userPaymentMethods, setUserPaymentMethods] = useState(
    defaultLeaderContext.userPaymentMethods,
  );
  const [defaultPaymentMethodForPayment, setDefaultPaymentMethodForPayment] =
    useState<UserPaymentMethodApi | null>(
      defaultLeaderContext.defaultPaymentMethodForPayment,
    );
  const [defaultPaymentMethodForIncome, setDefaultPaymentMethodForIncome] =
    useState<UserPaymentMethodApi | null>(
      defaultLeaderContext.defaultPaymentMethodForIncome,
    );

  useEffect(() => {
    if (userTradingGroupPricingPlan) {
      const userId = userTradingGroupPricingPlan.userTradingGroup?.user?.id;
      Promise.all([
        checkFollowerSubscription(userTradingGroupPricingPlan.id).then(
          ({data}) => {
            setFollowed(!!data.data);
            setFollowerSubscription(data.data ? data.data.id : null);
          },
        ),
        getUserTradingExperience(userId).then(({data}) => {
          setLeaderUserExperience(data.data);
        }),
        getUserPaymentsMethods().then(({data}) => {
          setUserPaymentMethods(data.data.paymentMethods);
          setDefaultPaymentMethodForIncome(
            data.data.defaultPaymentMethodForIncome,
          );
          setDefaultPaymentMethodForPayment(
            data.data.defaultPaymentMethodForPayment,
          );
        }),
      ]);
    }
  }, [userTradingGroupPricingPlan]);

  const checkSubscriptionPossibility = (
    leaderId: string,
    followerId: string,
  ) => {
    return jwtAxios.post(`/subscription/check-possibility`, {
      leaderId,
      followerId,
    });
  };

  const getUserPaymentsMethods = () => {
    return jwtAxios.get(`/user/payment-method/get-all/${user.user.id}`);
  };

  const fetchLeader = (userName: string) => {
    setLoading(true);
    Promise.all([
      getLeader(userName).then(({data}) => {
        setUserTradingGroupPricingPlan(data.data);
      }),
      getSubscriptionStopFollowReasons().then(({data}) => {
        setSubscriptionStopFollowReasons(data.data);
      }),
    ]).then(() => {
      setLoading(false);
    });
  };

  const getUserTradingExperience = async (userId: string | undefined) => {
    return jwtAxios.get(`/user/trading-experience/${userId}`);
  };

  const fetchIsFollower = (userTradingGroupPricingPlanId: string) => {
    checkFollowerSubscription(userTradingGroupPricingPlanId).then(({data}) => {
      setFollowed(!!data.data);
      setFollowerSubscription(data.data ? data.data.id : null);
    });
  };

  const checkFollowerSubscription = async (
    userTradingGroupPricingPlanId: string,
  ) => {
    return jwtAxios.get(
      `/subscription/follower/check/${user.user.id}/${userTradingGroupPricingPlanId}`,
    );
  };

  const getSubscriptionStopFollowReasons = async () => {
    return jwtAxios.get(`/subscription/stop-reason/get-all-active`);
  };

  const stopFollow = async (
    stopFollowReasonId: string | null,
    customReason: string | null,
  ) => {
    return jwtAxios.patch(`/subscription/stop-follow`, {
      subscriptionId: followerSubscription,
      stopFollowReasonId: stopFollowReasonId,
      customReason: customReason,
    });
  };

  const getLeader = async (userName: string) => {
    return jwtAxios.get(
      `/user/trading-group/pricing-plan/get-by-name/${userName}`,
    );
  };

  return (
    <LeaderContext.Provider
      value={{
        userTradingGroupPricingPlan,
        loading,
        isFollowed,
        subscriptionStopFollowReasons,
        followerSubscription,
        leaderUserExperience,
        userPaymentMethods,
        defaultPaymentMethodForPayment,
        defaultPaymentMethodForIncome,
      }}>
      <LeaderActionsContext.Provider
        value={{
          fetchLeader,
          checkFollowerSubscription,
          fetchIsFollower,
          setFollowed,
          stopFollow,
          setFollowerSubscription,
          checkSubscriptionPossibility,
        }}>
        {children}
      </LeaderActionsContext.Provider>
    </LeaderContext.Provider>
  );
};

export default LeaderProvider;
