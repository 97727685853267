import React from 'react';

const Signin = React.lazy(() => import('./Signin'));
const Signup = React.lazy(() => import('./Signup'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword'));
const RecoverPassword = React.lazy(() => import('./RecoverPassword'));
const SuccessForgotPassword = React.lazy(() => import('./SuccessForgotPassword'));
const ConfirmSignupAwsCognito = React.lazy(() =>
  import('./ConfirmSignupAwsCognito'),
);
const ResetPasswordAwsCognito = React.lazy(() =>
  import('./ResetPasswordAwsCognito'),
);

export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/recover-password/:recoverCode',
    element: <RecoverPassword />,
    exact: true,
  },
  {
    path: '/success-forgot-password',
    element: <SuccessForgotPassword />,
  },
  {
    path: '/confirm-signup',
    element: <ConfirmSignupAwsCognito />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordAwsCognito />,
  },
];
