// ForJWT Auth
import {getUserFromJwtAuth} from './helper/AuthHelper';
import {
  LEADER_TRADING_GROUP,
  useJWTAuth,
  useJWTAuthActions,
} from '../services/auth/jwt-auth/JWTAuthProvider';

export const useAuthUser = () => {
  const {user, tradingGroups, isAuthenticated, isLoading} = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
    tradingGroups
  };
};

export const useIsLeader = () => {
  const {user} = useJWTAuth();

  if (user.user.tradingGroups.indexOf(LEADER_TRADING_GROUP) !== -1) {
    return true;
  }

  return false;
};

export const useAuthMethod = () => {
  const {signInUser, signUpUser, logout} = useJWTAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
  };
};

export const useUserForgotPassword = () => {
  const {createUserForgotPassword} = useJWTAuthActions();

  return {
    createUserForgotPassword,
  };
};

export const useCheckUserRecoverCode = () => {
  const {checkUserRecoverCode} = useJWTAuthActions();

  return {
    checkUserRecoverCode,
  };
};

export const useRecoverUserPassword = () => {
  const {recoverUserPassword} = useJWTAuthActions();

  return {
    recoverUserPassword,
  };
};

export const useUpdateUserProfileImage = () => {
  const {updateUserProfileImage} = useJWTAuthActions();

  return {
    updateUserProfileImage,
  };
};

export const useRemoveUserProfileImage = () => {
  const {removeUserProfileImage} = useJWTAuthActions();

  return {
    removeUserProfileImage,
  };
};

export const useSetUserImage = () => {
  const {setUserImageUrl} = useJWTAuthActions();

  return {
    setUserImageUrl,
  };
};

//For Firebase Auth
// import {
//   useFirebase,
//   useFirebaseActions,
// } from '../services/auth/firebase/FirebaseAuthProvider';
// import {getUserFromFirebase} from './helper/AuthHelper';
//
// export const useAuthUser = () => {
//   const {user, isAuthenticated, isLoading} = useFirebase();
//   return {
//     isLoading,
//     isAuthenticated,
//     user: getUserFromFirebase(user),
//   };
// };
//
// export const useAuthMethod = () => {
//   const {
//     signInWithEmailAndPassword,
//     createUserWithEmailAndPassword,
//     signInWithPopup,
//     logout,
//   } = useFirebaseActions();
//
//   return {
//     signInWithEmailAndPassword,
//     createUserWithEmailAndPassword,
//     signInWithPopup,
//     logout,
//   };
// };

/*
// For AWS Auth
import {getUserFromAWS} from './helper/AuthHelper';
import {
  useAwsCognito,
  useAwsCognitoActions,
} from '../services/auth/aws-cognito/AWSAuthProvider';

export const useAuthUser = () => {
  const {user, isAuthenticated, isLoading} = useAwsCognito();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromAWS(user),
  };
};

export const useAuthMethod = () => {
  const {
    signIn,
    signUpCognitoUser,
    confirmCognitoUserSignup,
    logout,
  } = useAwsCognitoActions();

  return {
    signIn,
    signUpCognitoUser,
    confirmCognitoUserSignup,
    logout,
  };
};*/
/*

//For Auth0
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import { getUserFromAuth0 } from "./helper/AuthHelper";

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  return {
    isLoading,
    isAuthenticated,
    user: useMemo(() => getUserFromAuth0(user), []),
  };
};

export const useAuthMethod = () => {
  const { loginWithRedirect, logout } = useAuth0();
  return { loginWithRedirect, logout };
};
*/
