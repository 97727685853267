import React, {createContext, useContext, useEffect, useState} from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {useAuthUser} from '../../@crema/utility/AuthHooks';
import moment from "moment/moment";

type UserProfileContextType = {
  name: string | null;
  email: string | null;
  firstName: string | null | undefined;
  secondName: string | null | undefined;
  lastName: string | null | undefined;
  gender: number | null | undefined;
  birthDate: string | null | undefined;
  isLoading: boolean;
};

interface UserProfileActionsContextType {
  updateUserProfile: Function;
  updateUserPassword: Function;
}

const defaultUserProfile = {
  name: null,
  email: null,
  firstName: null,
  secondName: null,
  lastName: null,
  gender: null,
  birthDate: null,
  isLoading: true,
};

export interface UpdateUserProfileRequest {
  userId: string;
  userName: string;
  firstName: string;
  secondName: string | null;
  lastName: string;
  email: string;
  gender: number;
  birthDate: string;
}

export interface UpdateUserPasswordRequest {
  userId: string;
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export interface CreateUserForgotPasswordRequest {
  email: string;
}

const UserProfileContext =
  createContext<UserProfileContextType>(defaultUserProfile);

const UserProfileActionsContext = createContext<UserProfileActionsContextType>({
  updateUserProfile: () => {},
  updateUserPassword: () => {}
});

export const useUserProfileContext = () => useContext(UserProfileContext);
export const useUserProfileActionsContext = () =>
  useContext(UserProfileActionsContext);

const UserProfileProvider = ({children}: any) => {
  const [userProfile, setUserProfile] = useState(defaultUserProfile);
  const {user} = useAuthUser();

  useEffect(() => {
    getUserProfile(user.id)
      .then(({data}) => setUserProfile({...data, isLoading: false}))
      .catch(() =>
        setUserProfile({
          ...defaultUserProfile,
          isLoading: false,
        }),
      );
  }, []);

  const getUserProfile = async (id: string) => {
    return jwtAxios.get(`/user/profile/${id}`);
  };

  const updateUserProfile = (values: UpdateUserProfileRequest) => {
    const secondName = values.secondName ? values.secondName : null;
    const gender = values.gender ? +values.gender : null;
    const birthDate = values.birthDate
      ? moment(values.birthDate).format('YYYY-MM-DD')
      : null;
    return jwtAxios.patch(`/profile/update`, {
      ...values,
      userId: user.id,
      secondName,
      gender,
      birthDate,
    });
  };

  const updateUserPassword = (values: UpdateUserPasswordRequest) => {
    return jwtAxios.patch(`/user/change-password`, {
      ...values,
      userId: user.id,
    });
  };

  return (
    <UserProfileContext.Provider value={{...userProfile}}>
      <UserProfileActionsContext.Provider
        value={{
          updateUserProfile,
          updateUserPassword,
        }}>
        {children}
      </UserProfileActionsContext.Provider>
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
