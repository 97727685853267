import React from 'react';
import './index.style.less';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const MarketDataChart = ({data}) => {

  return (
    <ResponsiveContainer width='100%' height={500}>
      {data ? (
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis />
          <YAxis type="number" domain={['dataMin', 'dataMax']} />
          <Tooltip />
          <Legend />
          <Line
            name={'High Price, $'}
            type='linear'
            dataKey='high'
            stroke='#8884d8'
            activeDot={{r: 8}}
          />
          <Line
            name={'Low Price, $'}
            type='linear'
            dataKey='low'
            stroke='#82ca9d'
          />
        </LineChart>
      ) : (
        <></>
      )}
    </ResponsiveContainer>
  );
};

export default MarketDataChart;

MarketDataChart.propTypes = {
  data: PropTypes.object,
};
