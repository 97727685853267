import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';
import LeaderProvider from '../../services/leader/LeaderProvider';
import SubscriptionProvider from '../../services/subscription/SubscriptionProvider';
import InvoiceProvider from '../../services/invoice/InvoiceProvider';
import MarketDataListProvider from '../../services/marketData/MarketDataListProvider';
import MarketDataProvider from '../../services/marketData/MarketDataProvider';
import MarketDataPage from './MarketDataPage';
import Portfolio from './LeaderPage/Portfolio';
import TraderProvider from '../../services/dashboard/TraderProvider';
import FollowerProvider from '../../services/follower/FollowerProvider';
import FollowerPortfolio from './FollowerPortfolio';

const Dashboard = React.lazy(() => import('./Dashboard'));
const HomeAuthorized = React.lazy(() => import('./HomeAuthorized'));
const People = React.lazy(() => import('./Connections'));
const Settings = React.lazy(() => import('./Settings'));
const Analytics = React.lazy(() => import('./Analytics'));
const Subscription = React.lazy(() => import('./Subscription'));
const Terms = React.lazy(() => import('./Terms'));
const LeaderPage = React.lazy(() => import('./LeaderPage'));
const SubscriptionPage = React.lazy(() => import('./SubscriptionPage'));
const InvoicePage = React.lazy(() => import('./InvoicePage'));

export const samplePagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/',
    element: <HomeAuthorized />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboard/overview',
    element: <Dashboard />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboard/analitycs',
    element: (
      <MarketDataListProvider>
        <Analytics />
      </MarketDataListProvider>
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboard/subscription/:tab?',
    element: <Subscription />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboard/people',
    element: <People />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboard/terms',
    element: <Terms />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/dashboard/settings/:tab?',
    element: <Settings />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/people/leaders/:name/portfolio',
    exact: true,
    element: (
      <LeaderProvider>
        <TraderProvider>
          <Portfolio />
        </TraderProvider>
      </LeaderProvider>
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/people/followers/:name/portfolio',
    exact: true,
    element: (
      <FollowerProvider>
        <FollowerPortfolio />
      </FollowerProvider>
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/people/leaders/:name',
    element: (
      <LeaderProvider>
        <LeaderPage />
      </LeaderProvider>
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/subscription/:subscriptionId',
    element: (
      <SubscriptionProvider>
        <SubscriptionPage />
      </SubscriptionProvider>
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/invoice/:invoiceId',
    element: (
      <InvoiceProvider>
        <InvoicePage />
      </InvoiceProvider>
    ),
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/market-data/:contractId',
    element: (
      <MarketDataProvider>
        <MarketDataPage />
      </MarketDataProvider>
    ),
  },
];
