import React from 'react';
import './index.style.less';
import {useAuthUser, useIsLeader} from '../../../../utility/AuthHooks';
import {
  FOLLOWER_TRADING_GROUP,
  LEADER_TRADING_GROUP,
} from '../../../../services/auth/jwt-auth/JWTAuthProvider';
import IntlMessages from '../../../../utility/IntlMessages';
import {Popover} from "antd";
import {FaQuestionCircle} from "react-icons/fa";
import {useIntl} from "react-intl";

const UserTradingGroupLabel = () => {
  const {user} = useAuthUser();
  const {messages} = useIntl();
  const isLeader = useIsLeader();

  const description = isLeader
    ? messages['tradingGroup.leaderDescription']
    : messages['tradingGroup.followerDescription'];

  const getUserTradingGroup = () => {
    const userTradingGroups = user.tradingGroups;
    if (userTradingGroups.indexOf(LEADER_TRADING_GROUP) !== -1) {
      return 'tradingGroup.leader';
    }

    if (userTradingGroups.indexOf(FOLLOWER_TRADING_GROUP) !== -1) {
      return 'tradingGroup.follower';
    }

    return null;
  };

  return (
    <span className={'user-trading-group-label'}>
      <span className={'user-trading-group-prelabel'}>
        <IntlMessages id='tradingGroup.label' />
        <IntlMessages id={getUserTradingGroup()} />
      </span>
      <span className={'user-trading-group-popover'}>
        <Popover placement="bottom" content={description} title={`Who is ${messages[getUserTradingGroup()]}?`}>
          <span><FaQuestionCircle /></span>
        </Popover>
      </span>
    </span>
  );
};

export default UserTradingGroupLabel;
