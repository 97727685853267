import styled from 'styled-components';
import AppTableContainer from '../../../../@crema/core/AppTableContainer';

export const StyledMarketingUserInfo = styled.div`
  display: flex;
  align-items: center;

  & .ant-avatar {
    width: 40px;
    height: 40px;
    margin-right: 14px;
    position: relative;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 14px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      mix-blend-mode: overlay;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0.75),
        rgba(0, 0, 0, 0)
      );
      z-index: 1;
    }
  }
`;

export const StyledMarketingUserInfoContent = styled.div`
  font-size: 13px;
  flex: 1;

  & h3 {
    margin-bottom: 2px;
    font-weight: @font-weight-medium;
    font-size: @font-size-base;
  }

  & p {
    color: rgb(119, 119, 119);
    margin-bottom: 0;
  }
`;

export const StyledGraphItem = styled.div`
  & span {
    margin-right: 8px;
    display: inline-block;
    color: @text-color-secondary;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 8px;
    }
  }
`;

export const StyledMarketingCampaignTable = styled(AppTableContainer)`
  & .ant-table-thead > tr > th {
    font-size: 18px;
    padding: 8px;
    font-weight: @font-weight-bold;
    background-color: transparent;

    &:first-child {
      padding-left: 20px;

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: 20px;
      }
    }

    &:last-child {
      padding-right: 20px;
      text-align: center;

      [dir='rtl'] & {
        padding-right: 0;
        padding-left: 20px;
      }
    }
  }

  & .ant-table-tbody > tr > td {
    font-size: 16px;
    padding: 8px;

    &:first-child {
      padding-left: 20px;

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: 20px;
      }
    }

    &:last-child {
      padding-right: 20px;
      text-align: center;

      [dir='rtl'] & {
        padding-right: 0;
        padding-left: 20px;
      }
    }
  }
`;
