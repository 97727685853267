import {
  useLeaderActionsContext,
  useLeaderContext,
} from '../../services/leader/LeaderProvider';

export const useLeader = () => {
  const {
    userTradingGroupPricingPlan,
    loading,
    isFollowed,
    subscriptionStopFollowReasons,
    followerSubscription,
    userPaymentMethods,
    defaultPaymentMethodForPayment,
    defaultPaymentMethodForIncome,
  } = useLeaderContext();
  return {
    userTradingGroupPricingPlan,
    loading,
    isFollowed,
    subscriptionStopFollowReasons,
    followerSubscription,
    userPaymentMethods,
    defaultPaymentMethodForPayment,
    defaultPaymentMethodForIncome,
  };
};

export const useLeaderActions = () => {
  const {
    fetchLeader,
    checkFollowerSubscription,
    fetchIsFollower,
    setFollowed,
    stopFollow,
    setFollowerSubscription,
    checkSubscriptionPossibility,
  } = useLeaderActionsContext();

  return {
    fetchLeader,
    checkFollowerSubscription,
    fetchIsFollower,
    setFollowed,
    stopFollow,
    setFollowerSubscription,
    checkSubscriptionPossibility,
  };
};
