import React from 'react';
import {Provider} from 'react-redux';

import './shared/styles/crema.less';
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from './@crema';
import configureStore from './redux/store';
import {BrowserRouter} from 'react-router-dom';
import './@crema/services/index';
import JWTAuthProvider from './@crema/services/auth/jwt-auth/JWTAuthProvider';
import AppNotificationProvider from './services/notification/AppNotificationProvider';
import AppSocketProvider from './services/socket/AppSocketProvider';
import TradingProvidersAuthProvider from './services/tradingProvidersAuth/TradingProvidersAuthProvider';

const store = configureStore();
const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <AppThemeProvider>
        <AppLocaleProvider>
          <BrowserRouter>
            <AppNotificationProvider>
              <JWTAuthProvider>
                <AppSocketProvider>
                  <AuthRoutes>
                    <TradingProvidersAuthProvider>
                      <AppLayout />
                    </TradingProvidersAuthProvider>
                  </AuthRoutes>
                </AppSocketProvider>
              </JWTAuthProvider>
            </AppNotificationProvider>
          </BrowserRouter>
        </AppLocaleProvider>
      </AppThemeProvider>
    </Provider>
  </AppContextProvider>
);

export default App;
