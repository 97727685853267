import React, {createContext, useContext, useState} from 'react';
import {
  TradingProvidersAuthActionsContextType,
  TradingProvidersAuthContextType,
} from './types';
import {useJWTAuth} from '../../@crema/services/auth/jwt-auth/JWTAuthProvider';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';

const defaultTradingProvidersAuthContext = {
  loading: false,
  tokenId: null,
};

const TradingProvidersAuthContext =
  createContext<TradingProvidersAuthContextType>(
    defaultTradingProvidersAuthContext,
  );

const TradingProvidersAuthActionsContext =
  createContext<TradingProvidersAuthActionsContextType>({
    createTradeStationAccessToken: () => {},
    setLoading: () => {},
    setTokenId: () => {},
  });

export const useTradingProvidersAuthContext = () =>
  useContext(TradingProvidersAuthContext);
export const useTradingProvidersAuthActionsContext = () =>
  useContext(TradingProvidersAuthActionsContext);

const TradingProvidersAuthProvider = ({children}: any) => {
  const {user} = useJWTAuth();
  const [loading, setLoading] = useState(
    defaultTradingProvidersAuthContext.loading,
  );
  const [tokenId, setTokenId] = useState(
    defaultTradingProvidersAuthContext.tokenId,
  );

  const createTradeStationAccessToken = async (code: string) => {
    return new Promise((resolve, reject) => {
      jwtAxios
        .post(`/trading-provider/tradestation/access-token/create`, {
          code,
          userId: user.user.id,
        })
        .then(({data}) => {
          setTokenId(data.tokenId);
          document.cookie =
            'did=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          document.cookie =
            'did_compat=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          resolve(data.tokenId);
        })
        .catch((e) => {
          setTokenId(null);
          reject(e);
        });
    });
  };

  return (
    <TradingProvidersAuthContext.Provider
      value={{
        loading,
        tokenId,
      }}>
      <TradingProvidersAuthActionsContext.Provider
        value={{
          createTradeStationAccessToken,
          setLoading,
          setTokenId,
        }}>
        {children}
      </TradingProvidersAuthActionsContext.Provider>
    </TradingProvidersAuthContext.Provider>
  );
};

export default TradingProvidersAuthProvider;
