const {REACT_APP_SERVER_URL} = process.env;
const {REACT_APP_MARKET_INSTRUMENT_IMG_DIR} = process.env;

export const getRandomFloat = (min: number, max: number, decimals: number) => {
  const str = (Math.random() * (max - min) + min).toFixed(decimals);

  return parseFloat(str);
};

export const getMarketInstrumentImgDir = () => {
  return `${REACT_APP_SERVER_URL}/${REACT_APP_MARKET_INSTRUMENT_IMG_DIR}`;
};
