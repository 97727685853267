import React from 'react';
import {FaCog, FaHandHoldingUsd, FaRegFileAlt, FaRegListAlt, FaSignal, FaUserAlt} from "react-icons/fa";

const routesConfig = [
  {
    id: 'app',
    title: '',
    messageId: '',
    type: 'group',
    children: [
      {
        id: 'overview',
        title: 'My Dashboard',
        messageId: 'sidebar.dashboard.overview',
        type: 'item',
        icon: <FaRegListAlt />,
        path: '/dashboard/overview',
      },
      {
        id: 'statistics',
        title: 'Analitycs',
        messageId: 'sidebar.dashboard.statistics',
        type: 'item',
        icon: <FaSignal />,
        path: '/dashboard/analitycs',
      },
      {
        id: 'subscription',
        title: 'Offerings & Subscriptions',
        messageId: 'sidebar.dashboard.subscription',
        type: 'item',
        icon: <FaHandHoldingUsd />,
        path: '/dashboard/subscription',
      },
      {
        id: 'people',
        title: 'Connections',
        messageId: 'sidebar.dashboard.people',
        type: 'item',
        icon: <FaUserAlt />,
        path: '/dashboard/people',
      },
      {
        id: 'terms',
        title: 'Terms & Conditions',
        messageId: 'sidebar.dashboard.terms',
        type: 'item',
        icon: <FaRegFileAlt />,
        path: '/dashboard/terms',
      },
      {
        id: 'settings',
        title: 'Settings',
        messageId: 'sidebar.dashboard.settings',
        type: 'item',
        icon: <FaCog />,
        path: '/dashboard/settings',
      },
    ],
  },
];
export default routesConfig;
