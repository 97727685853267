import React from 'react';
import UserProfileProvider from '../../services/user/UserProfileProvider';

const UserProfile = React.lazy(() => import('./UserProfile'));
export const profileConfig = [
  {
    path: '/my-profile',
    element: <UserProfileProvider><UserProfile /></UserProfileProvider>,
  },
];
