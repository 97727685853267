import React from 'react';
import MarketingTable from './MarketingTable';
import PropTypes from 'prop-types';

const MarketingCampaign = ({marketingCampaign}) => {
  return <MarketingTable marketingCampaignData={marketingCampaign} />;
};

export default MarketingCampaign;

MarketingCampaign.propTypes = {
  marketingCampaign: PropTypes.array,
};
