import React, {createContext, useContext, useEffect, useState} from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {SubscriptionActionsContextType, SubscriptionContextType} from './types';

const defaultSubscriptionContext = {
  subscription: null,
  subscriptionStopFollowReasons: [],
  loading: false,
};

const SubscriptionContext = createContext<SubscriptionContextType>(
  defaultSubscriptionContext,
);

const SubscriptionActionsContext =
  createContext<SubscriptionActionsContextType>({
    fetchSubscription: () => {},
    stopFollow: () => {},
  });

export const useSubscriptionContext = () => useContext(SubscriptionContext);
export const useSubscriptionActionsContext = () =>
  useContext(SubscriptionActionsContext);

const SubscriptionProvider = ({children}: any) => {
  const [subscription, setSubscription] = useState(
    defaultSubscriptionContext.subscription,
  );
  const [subscriptionStopFollowReasons, setSubscriptionStopFollowReasons] =
    useState(defaultSubscriptionContext.subscriptionStopFollowReasons);
  const [loading, setLoading] = useState(defaultSubscriptionContext.loading);

  useEffect(() => {
    getSubscriptionStopFollowReasons().then(({data}) => {
      setSubscriptionStopFollowReasons(data.data);
    });
  }, []);

  const fetchSubscription = (subscriptionId: string) => {
    setLoading(true);
    getSubscription(subscriptionId)
      .then(({data}) => {
        setSubscription(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const stopFollow = async (
    subscriptionId: string,
    stopFollowReasonId: string | null,
    customReason: string | null,
  ) => {
    return jwtAxios.patch(`/subscription/stop-follow`, {
      subscriptionId: subscriptionId,
      stopFollowReasonId: stopFollowReasonId,
      customReason: customReason,
    });
  };

  const getSubscriptionStopFollowReasons = async () => {
    return jwtAxios.get(`/subscription/stop-reason/get-all-active`);
  };

  const getSubscription = async (subscriptionId: string) => {
    return jwtAxios.get(`/subscription/${subscriptionId}`);
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        subscriptionStopFollowReasons,
        loading,
      }}>
      <SubscriptionActionsContext.Provider
        value={{
          fetchSubscription,
          stopFollow,
        }}>
        {children}
      </SubscriptionActionsContext.Provider>
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
