import React, {createContext, useContext, useState} from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {FollowerActionsContextType, FollowerContextType} from './types';

const defaultFollowerContext = {
  loading: false,
  marketingInstrumentStatistics: null,
};

const FollowerContext = createContext<FollowerContextType>(
  defaultFollowerContext,
);

const FollowerActionsContext = createContext<FollowerActionsContextType>({
  fetchFollowerMarketingInstrumentStatistics: () => {},
});

export const useFollowerContext = () => useContext(FollowerContext);
export const useFollowerActionsContext = () =>
  useContext(FollowerActionsContext);

const FollowerProvider = ({children}: any) => {
  const [loading, setLoading] = useState(defaultFollowerContext.loading);
  const [marketingInstrumentStatistics, setMarketingInstrumentStatistics] =
    useState(defaultFollowerContext.marketingInstrumentStatistics);

  const fetchFollowerMarketingInstrumentStatistics = (userName: string) => {
    setLoading(true);
    Promise.all([
      getFollowerMarketingInstrumentStatistics(userName).then(({data}) => {
        setMarketingInstrumentStatistics(data.data);
      }),
    ]).then(() => {
      setLoading(false);
    });
  };

  const getFollowerMarketingInstrumentStatistics = async (
    userName: string | undefined,
  ) => {
    return jwtAxios.get(`/market-instrument-statistics/follower/${userName}`);
  };

  return (
    <FollowerContext.Provider
      value={{
        marketingInstrumentStatistics,
        loading,
      }}>
      <FollowerActionsContext.Provider
        value={{
          fetchFollowerMarketingInstrumentStatistics,
        }}>
        {children}
      </FollowerActionsContext.Provider>
    </FollowerContext.Provider>
  );
};

export default FollowerProvider;
