import React, {createContext, useContext, useEffect, useState} from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {
  getFilterQueryString,
  getOffsetString,
  getSortString,
} from '../../utility/utility/filter';
import {Pagination, SortType} from '../../utility/types/table';
import {
  UserTradingGroupPricingPlanApi,
} from '../../utility/types/user';
import {
  FilterOperator,
  TraderFilter,
  TraderFilterValues,
} from '../../utility/types/filter';
import {useJWTAuth} from '../../@crema/services/auth/jwt-auth/JWTAuthProvider';
import {FollowLeaderRequest} from './types';

type TraderContextType = {
  traders: {
    data: UserTradingGroupPricingPlanApi[];
    total: number;
  };
  loading: boolean;
  filters: TraderFilter;
  pagination: Pagination;
  sort: SortType;
};

interface TraderActionsContextType {
  handleFiltersChanged: Function;
  handleFiltersReset: Function;
  handlePageChanged: Function;
  handlePageSizeChanged: Function;
  handleSortChanged: Function;
  followLeader: Function;
  stopFollowLeader: Function;
  fetchTraders: Function;
  setFilters: Function;
}

const defaultTraderContext = {
  filters: {
    name: {value: undefined, operator: FilterOperator.CONTAINS},
    isApproved: {value: 1, operator: FilterOperator.EQUAL},
    riskLevel: {value: 1, operator: FilterOperator.EQUAL},
    newLeader: {value: 1, operator: FilterOperator.EQUAL},
    userId: {value: undefined, operator: FilterOperator.NOT_EQUAL},
  },
  loading: false,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  sort: {
    name: 'ASC',
  },
  traders: {
    data: [],
    total: 0,
  },
};

const TraderContext = createContext<TraderContextType>(defaultTraderContext);

const TraderActionsContext = createContext<TraderActionsContextType>({
  handleFiltersChanged: () => {},
  handleFiltersReset: () => {},
  handlePageChanged: () => {},
  handlePageSizeChanged: () => {},
  handleSortChanged: () => {},
  followLeader: () => {},
  stopFollowLeader: () => {},
  fetchTraders: () => {},
  setFilters: () => {},
});

export const useTraderContext = () => useContext(TraderContext);
export const useTraderActionsContext = () => useContext(TraderActionsContext);

const TraderProvider = ({children}: any) => {
  const {user} = useJWTAuth();
  const [traders, setTraders] = useState(defaultTraderContext.traders);
  const [filters, setFilters] = useState<TraderFilter>({
    ...defaultTraderContext.filters,
    userId: {value: user.user.id, operator: FilterOperator.NOT_EQUAL},
  });
  const [loading, setLoading] = useState(defaultTraderContext.loading);
  const [pagination, setPagination] = useState(defaultTraderContext.pagination);
  const [sort, setSort] = useState<SortType>(defaultTraderContext.sort);

  useEffect(() => {
    fetchTraders();
  }, []);

  useEffect(() => {
    fetchTraders();
  }, [pagination, sort]);

  const fetchTraders = () => {
    setLoading(true);
    Promise.all([
      getTraders().then(({data}) => {
        setTraders({data: data.data.traders, total: data.data.total});
      }),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getTraders = async () => {
    const queryString = getQueryString();

    return jwtAxios.get(
      `/user/trading-group/pricing-plan/leaders/${queryString}`,
    );
  };

  const followLeader = (request: FollowLeaderRequest) => {
    return jwtAxios.post(`/subscription/start-follow`, {
      ...request,
      userId: user.user.id,
    });
  };

  const stopFollowLeader = (subscriptionId: string) => {
    return jwtAxios.patch(`/subscription/stop-follow`, {
      subscriptionId,
    });
  };

  const getQueryString = () => {
    //TODO To implement filter later
    let queryString = '';
    // eslint-disable-next-line no-unused-vars
    const filterQueryString = getFilterQueryString(filters);

    // eslint-disable-next-line no-unused-vars
    const offsetString = getOffsetString(pagination.page, pagination.pageSize);

    // eslint-disable-next-line no-unused-vars
    const sortString = getSortString(sort);

    return queryString.concat(`?${filterQueryString}`);

    // return queryString.concat(
    //   '?',
    //   offsetString,
    //   sortString ? `&${sortString}` : sortString,
    //   filterQueryString ? `&${filterQueryString}` : filterQueryString,
    // );
  };

  const handleFiltersChanged = (values: TraderFilterValues) => {
    setFilters({
      ...filters,
      name: values.name
        ? {...filters.name, value: encodeURIComponent(values.name)}
        : {...filters.name, value: undefined},
    });
  };

  const handleFiltersReset = () => {
    setFilters(defaultTraderContext.filters);
  };

  const handlePageChanged = (page: number, pageSize: number) => {
    setPagination({...pagination, page, pageSize});
  };

  const handlePageSizeChanged = (page: number, pageSize: number) => {
    setPagination({...pagination, page, pageSize});
  };

  const handleSortChanged = (sort: SortType) => {
    setSort(sort);
  };

  return (
    <TraderContext.Provider
      value={{traders, loading, filters, pagination, sort}}>
      <TraderActionsContext.Provider
        value={{
          handleFiltersChanged,
          handleFiltersReset,
          handlePageChanged,
          handlePageSizeChanged,
          handleSortChanged,
          followLeader,
          stopFollowLeader,
          fetchTraders,
          setFilters,
        }}>
        {children}
      </TraderActionsContext.Provider>
    </TraderContext.Provider>
  );
};

export default TraderProvider;
