import React from 'react';
import './index.style.less';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';
import SignInJwtAuth from './SigninJwtAuth';
import AuthSignInWrapper from '../AuthSignInWrapper';

const Signin = () => {
  return (
    <AuthSignInWrapper>
      <AppPageMetadata title='Login' />
      <SignInJwtAuth />
    </AuthSignInWrapper>
  );
};

export default Signin;
