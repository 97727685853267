import {SortType} from '../types/table';

export interface IFilter {}

export const getFilterQueryString = (filters: IFilter) => {
  const queryStringComponents: Array<string> = [];

  Object.keys(filters).forEach((key: string, index: number) => {
    const value = filters[key as keyof IFilter];
    // @ts-ignore
    if (value.value) {
      // @ts-ignore
      const queryStringComponent = `filters[${index}][field]=${key}&filters[${index}][operator]=${value.operator}&filters[${index}][value]=${value.value}`;
      queryStringComponents.push(queryStringComponent);
    }
  });

  return queryStringComponents.length > 0
    ? `${queryStringComponents.join('&')}`
    : '';
};

export const getOffset = (page: number, pageSize: number) => {
  return (page * pageSize).toString();
};

export const getOffsetString = (page: number, pageSize: number) => {
  const offset = pageSize * (page - 1);

  return `limit=${pageSize}&offset=${offset}`;
};

export const getSortString = (sort: SortType) => {
  const orderBy = Object.keys(sort)[0];
  const order = Object.values(sort)[0];

  return `orderBy=${orderBy}&order=${order}`;
};

export const sortOrderTransform = (order: string) => {
  return order === 'ascend' ? 'ASC' : 'DESC';
}


