import React, {createContext, useContext, useState} from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {
  InvoiceContextType,
  InvoiceOneApi,
  InvoicesActionsContextType,
} from './types';
import {useJWTAuth} from '../../@crema/services/auth/jwt-auth/JWTAuthProvider';
import {UserPaymentMethodApi} from '../payment/PaymentInformationProvider';

const defaultInvoiceContext = {
  userPaymentMethods: [],
  selectedUserPaymentMethod: null,
  invoice: null,
  selectedPaymentProvider: null,
  stripePaymentIntentClientSecret: null,
  paypalClientToken: null,
  loading: false,
  loadingPaymentProviderData: false,
  saveUserPaymentMethod: false,
};

const InvoiceContext = createContext<InvoiceContextType>(defaultInvoiceContext);

const InvoiceActionsContext = createContext<InvoicesActionsContextType>({
  fetchInvoice: () => {},
  setSelectedUserPaymentMethod: () => {},
  createStripePayment: () => {},
  createPaypalPayment: () => {},
  setSelectedPaymentProvider: () => {},
  fetchStripePaymentIntentClientSecret: () => {},
  fetchPaypalClientToken: () => {},
  confirmStripePayment: () => {},
  setSaveUserPaymentMethod: () => {},
  createPaypalPaymentByNonce: () => {},
});

export const useInvoiceContext = () => useContext(InvoiceContext);
export const useInvoiceActionsContext = () => useContext(InvoiceActionsContext);

const InvoiceProvider = ({children}: any) => {
  const {user} = useJWTAuth();
  const [invoice, setInvoice] = useState<InvoiceOneApi | null>(
    defaultInvoiceContext.invoice,
  );
  const [loading, setLoading] = useState(defaultInvoiceContext.loading);
  const [saveUserPaymentMethod, setSaveUserPaymentMethod] = useState(
    defaultInvoiceContext.saveUserPaymentMethod,
  );
  const [loadingPaymentProviderData, setLoadingPaymentProviderData] = useState(
    defaultInvoiceContext.loadingPaymentProviderData,
  );
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState(
    defaultInvoiceContext.selectedPaymentProvider,
  );
  const [userPaymentMethods, setUserPaymentMethods] = useState(
    defaultInvoiceContext.userPaymentMethods,
  );
  const [selectedUserPaymentMethod, setSelectedUserPaymentMethod] =
    useState<UserPaymentMethodApi | null>(
      defaultInvoiceContext.selectedUserPaymentMethod,
    );
  const [stripePaymentIntentClientSecret, setStripePaymentIntentClientSecret] =
    useState<string | null>(
      defaultInvoiceContext.stripePaymentIntentClientSecret,
    );
  const [paypalClientToken, setPaypalClientToken] = useState<string | null>(
    defaultInvoiceContext.paypalClientToken,
  );

  const fetchInvoice = (invoiceId: string) => {
    setLoading(true);
    Promise.all([
      getInvoice(invoiceId).then(({data}) => {
        setInvoice(data.data);
      }),
      getUserPaymentsMethods().then(({data}) => {
        setUserPaymentMethods(data.data.paymentMethods);
        const defaultUserPaymentMethod = data.data.paymentMethods.find(
          (userPaymentMethod: UserPaymentMethodApi) =>
            userPaymentMethod.isDefaultForPayment,
        );
        setSelectedUserPaymentMethod(defaultUserPaymentMethod || null);
      }),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const createStripePayment = () => {
    return jwtAxios.post('/payment/stripe/payment-method/create', {
      userId: user.user.id,
      invoiceId: invoice?.id,
      userPaymentMethodId: selectedUserPaymentMethod?.id,
    });
  };

  const confirmStripePayment = (
    invoiceId: string,
    stripePaymentIntent: string,
    savePaymentMethodForFuture: boolean,
  ) => {
    return jwtAxios.post('/payment/stripe/confirm', {
      userId: user.user.id,
      invoiceId: invoiceId,
      stripePaymentIntentId: stripePaymentIntent,
      savePaymentMethod: savePaymentMethodForFuture,
    });
  };

  const fetchStripePaymentIntentClientSecret = async () => {
    setLoadingPaymentProviderData(true);
    try {
      const response = await createStripePaymentIntentClientSecret();
      setStripePaymentIntentClientSecret(
        response.data.data.stripePaymentIntentClientSecret,
      );
      setLoadingPaymentProviderData(false);
      return Promise.resolve();
    } catch (e) {
      setLoadingPaymentProviderData(false);
      return Promise.reject();
    }
  };

  const fetchPaypalClientToken = async () => {
    setLoadingPaymentProviderData(true);
    try {
      const response = await createPaypalClientToken();
      setPaypalClientToken(response.data.data.paypalClientToken);
      setLoadingPaymentProviderData(false);
      return Promise.resolve();
    } catch (e) {
      setLoadingPaymentProviderData(false);
      return Promise.reject();
    }
  };

  const createStripePaymentIntentClientSecret = () => {
    return jwtAxios.post('/payment/stripe/payment-intent/create', {
      userId: user.user.id,
      invoiceId: invoice?.id,
    });
  };

  const createPaypalClientToken = () => {
    return jwtAxios.post('/payment/paypal/client-token/create', {
      userId: user.user.id,
    });
  };

  const createPaypalPayment = () => {
    return jwtAxios.post('/payment/paypal/payment-method/create', {
      userId: user.user.id,
      invoiceId: invoice?.id,
      userPaymentMethodId: selectedUserPaymentMethod?.id,
    });
  };

  const createPaypalPaymentByNonce = (paypalPaymentNonce: string) => {
    return jwtAxios.post('/payment/paypal/create', {
      userId: user.user.id,
      invoiceId: invoice?.id,
      paypalPaymentNonce,
      savePaymentMethod: saveUserPaymentMethod
    });
  };

  const getUserPaymentsMethods = () => {
    return jwtAxios.get(`/user/payment-method/get-all/${user.user.id}`);
  };

  const getInvoice = async (invoiceId: string) => {
    return jwtAxios.get(`/invoice/${invoiceId}`);
  };

  return (
    <InvoiceContext.Provider
      value={{
        invoice,
        loading,
        userPaymentMethods,
        selectedUserPaymentMethod,
        selectedPaymentProvider,
        stripePaymentIntentClientSecret,
        loadingPaymentProviderData,
        saveUserPaymentMethod,
        paypalClientToken,
      }}>
      <InvoiceActionsContext.Provider
        value={{
          fetchInvoice,
          setSelectedUserPaymentMethod,
          createStripePayment,
          createPaypalPayment,
          setSelectedPaymentProvider,
          fetchStripePaymentIntentClientSecret,
          fetchPaypalClientToken,
          confirmStripePayment,
          setSaveUserPaymentMethod,
          createPaypalPaymentByNonce
        }}>
        {children}
      </InvoiceActionsContext.Provider>
    </InvoiceContext.Provider>
  );
};

export default InvoiceProvider;
