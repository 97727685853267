import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
import jwtAxios, {setAuthToken} from './jwt-api';
import {useNavigate} from "react-router-dom";

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

const {REACT_APP_BLOB_STORAGE_PROFILE_ENDPOINT} = process.env;

export const FOLLOWER_TRADING_GROUP = 1;
export const LEADER_TRADING_GROUP = 2;

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({children}) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    tradingGroups: [],
    isAuthenticated: false,
    isLoading: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('token');

      console.log('token', token);

      if (!token) {
        setJWTAuthData({
          user: undefined,
          tradingGroups: [],
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      setAuthToken(token);
      jwtAxios
        .get('/auth/user')
        .then(({data}) => {
          const authData = {...data};
          jwtAxios
            .get('/trading-group/get-all')
            .then(({data}) => {
              const avatarSrc = authData.user.avatar
                ? `${REACT_APP_BLOB_STORAGE_PROFILE_ENDPOINT}/${authData.user.avatar}`
                : null;
              const resUser = {...authData.user, avatar: avatarSrc};
              const resData = {...authData, user: resUser};
              setJWTAuthData({
                user: resData,
                tradingGroups: data.data,
                isLoading: false,
                isAuthenticated: true,
              });
            })
            .catch((e) => {
              console.log(e);
              setJWTAuthData({
                user: undefined,
                tradingGroups: [],
                isLoading: false,
                isAuthenticated: false,
              });
            });
        })
        .catch((e) => {
          console.log(e);
          setJWTAuthData({
            user: undefined,
            tradingGroups: [],
            isLoading: false,
            isAuthenticated: false,
          });
        });
    };

    getAuthUser();
  }, []);

  const signInUser = async ({email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('/auth/sign-in', {email, password});
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get('/auth/user');
      const tradingGroups = await jwtAxios.get('/trading-group/get-all');
      const avatarSrc = res.data.user.avatar
        ? `${REACT_APP_BLOB_STORAGE_PROFILE_ENDPOINT}/${res.data.user.avatar}`
        : null;
      const resUser = {...res.data.user, avatar: avatarSrc};
      const resData = {...res.data, user: resUser};
      setJWTAuthData({
        user: resData,
        tradingGroups: tradingGroups.data,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
      navigate('/dashboard/overview');
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.response.data.message});
    }
  };

  const signUpUser = async ({name, email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {data} = await jwtAxios.post('/auth/sign-up', {
        name,
        email,
        password,
      });
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);
      const res = await jwtAxios.get('/auth/user');
      const tradingGroups = await jwtAxios.get('/trading-group/get-all');
      setJWTAuthData({
        user: res.data,
        tradingGroups: tradingGroups.data,
        isAuthenticated: true,
        isLoading: false
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.response.data.message});
    }
  };

  const setUserImageUrl = (fileUrl) => {
    const newUser = {...firebaseData.user.user, avatar: fileUrl};
    const newFirebaseUser = {...firebaseData.user, user: newUser};

    setJWTAuthData({
      ...firebaseData,
      user: newFirebaseUser,
    });
    dispatch({type: FETCH_SUCCESS});
  };

  const updateUserProfileImage = (value) => {
    const data = new FormData();
    data.append('userId', firebaseData.user.user.id);
    data.append('userImage', value);
    return jwtAxios.post(`/user/image-update`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const removeUserProfileImage = () => {
    return jwtAxios.delete(
      `/user/image-remove/${firebaseData.user.user.id}`,
    );
  };

  const createUserForgotPassword = (values) => {
    return jwtAxios.post(`/user/create-forgot-password`, {...values});
  };

  const checkUserRecoverCode = (code) => {
    return jwtAxios.get(`/user/check-recover-code/${code}`);
  };

  const recoverUserPassword = (values) => {
    return jwtAxios.patch(`/user/recover-password`, {...values});
  };

  const logout = async () => {
    localStorage.removeItem('token');
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
    navigate('/signin');
    // history.replace('/signin');
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          createUserForgotPassword,
          logout,
          checkUserRecoverCode,
          recoverUserPassword,
          updateUserProfileImage,
          setUserImageUrl,
          removeUserProfileImage,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
