import React, {createContext, useContext, useEffect, useState} from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {
  getFilterQueryString,
  getOffsetString,
  getSortString,
} from '../../utility/utility/filter';
import {SortType} from '../../utility/types/table';
import {FilterOperator} from '../../utility/types/filter';
import {
  MarketDataListActionsContextType,
  MarketDataListContextType,
  MarketDataFilter,
} from './types';

const defaultMarketDataListContext = {
  filters: {
    symbol: {value: undefined, operator: FilterOperator.CONTAINS},
  },
  loading: false,
  pagination: {
    page: 1,
    pageSize: 20,
  },
  sort: {
    'marketData.symbol': 'ASC',
  },
  marketData: {
    data: [],
    total: 0,
  },
};

const MarketDataListContext = createContext<MarketDataListContextType>(
  defaultMarketDataListContext,
);

const MarketDataListActionsContext =
  createContext<MarketDataListActionsContextType>({
    handleFiltersChanged: () => {},
    handleFiltersReset: () => {},
    handlePageChanged: () => {},
    handlePageSizeChanged: () => {},
    handleSortChanged: () => {},
    fetchMarketData: () => {},
    setFilters: () => {},
  });

export const useMarketDataListContext = () => useContext(MarketDataListContext);
export const useMarketDataListActionsContext = () =>
  useContext(MarketDataListActionsContext);

const MarketDataListProvider = ({children}: any) => {
  const [marketData, setMarketData] = useState(
    defaultMarketDataListContext.marketData,
  );
  const [filters, setFilters] = useState<MarketDataFilter>({
    ...defaultMarketDataListContext.filters,
  });
  const [loading, setLoading] = useState(defaultMarketDataListContext.loading);
  const [pagination, setPagination] = useState(
    defaultMarketDataListContext.pagination,
  );
  const [sort, setSort] = useState<SortType>(defaultMarketDataListContext.sort);

  useEffect(() => {
    fetchMarketData();
  }, [pagination, sort]);

  const fetchMarketData = () => {
    setLoading(true);
    Promise.all([
      getMarketData().then(({data}) => {
        setMarketData({data: data.data.marketData, total: data.data.total});
      }),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getMarketData = async () => {
    const queryString = getQueryString();

    return jwtAxios.get(`/market-data/history/list${queryString}`);
  };

  const getQueryString = () => {
    //TODO To implement filter later
    let queryString = '';
    // eslint-disable-next-line no-unused-vars
    const filterQueryString = getFilterQueryString(filters);

    // eslint-disable-next-line no-unused-vars
    const offsetString = getOffsetString(pagination.page, pagination.pageSize);

    // eslint-disable-next-line no-unused-vars
    const sortString = getSortString(sort);

    return queryString.concat(`?${filterQueryString}&${sortString}`);

    // return queryString.concat(
    //   '?',
    //   offsetString,
    //   sortString ? `&${sortString}` : sortString,
    //   filterQueryString ? `&${filterQueryString}` : filterQueryString,
    // );
  };

  const handleFiltersChanged = () => {
    setFilters({
      ...filters,
    });
  };

  const handleFiltersReset = () => {
    setFilters(defaultMarketDataListContext.filters);
  };

  const handlePageChanged = (page: number, pageSize: number) => {
    setPagination({...pagination, page, pageSize});
  };

  const handlePageSizeChanged = (page: number, pageSize: number) => {
    setPagination({...pagination, page, pageSize});
  };

  const handleSortChanged = (sort: SortType) => {
    setSort(sort);
  };

  return (
    <MarketDataListContext.Provider
      value={{marketData, loading, filters, pagination, sort}}>
      <MarketDataListActionsContext.Provider
        value={{
          handleFiltersChanged,
          handleFiltersReset,
          handlePageChanged,
          handlePageSizeChanged,
          handleSortChanged,
          fetchMarketData,
          setFilters,
        }}>
        {children}
      </MarketDataListActionsContext.Provider>
    </MarketDataListContext.Provider>
  );
};

export default MarketDataListProvider;
