import React, {createContext, useContext, useState} from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {
  MarketDataActionsContextType,
  MarketDataContextType,
  MarketDataOneApi,
} from './types';

const defaultMarketDataContext = {
  marketData: [],
  loading: false,
};

const MarketDataContext = createContext<MarketDataContextType>(
  defaultMarketDataContext,
);

const MarketDataActionsContext = createContext<MarketDataActionsContextType>({
  fetchMarketData: () => {},
});

export const useMarketDataContext = () => useContext(MarketDataContext);
export const useMarketDataActionsContext = () =>
  useContext(MarketDataActionsContext);

const MarketDataProvider = ({children}: any) => {
  const [marketData, setMarketData] = useState<MarketDataOneApi[]>(
    defaultMarketDataContext.marketData,
  );
  const [loading, setLoading] = useState(defaultMarketDataContext.loading);

  const fetchMarketData = (contractId: string) => {
    setLoading(true);
    Promise.all([
      getContract(contractId).then(({data}) => {
        setMarketData(data.data);
      }),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getContract = async (contractId: string) => {
    return jwtAxios.get(`/market-data/history/${contractId}`);
  };

  return (
    <MarketDataContext.Provider
      value={{
        marketData,
        loading,
      }}>
      <MarketDataActionsContext.Provider
        value={{
          fetchMarketData,
        }}>
        {children}
      </MarketDataActionsContext.Provider>
    </MarketDataContext.Provider>
  );
};

export default MarketDataProvider;
