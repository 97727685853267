import React, {useEffect} from 'react';
import AppRowContainer from '../../../@crema/core/AppRowContainer';
import {Avatar, Col} from 'antd';
import './index.style.less';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';
import {useMatch} from 'react-router-dom';
import moment from 'moment';
import {
  useFollower,
  useFollowerActions,
} from '../../../utility/hooks/FollowerHooks';
import {useAuthUser} from '../../../@crema/utility/AuthHooks';
import AppAnimateGroup from '../../../@crema/core/AppAnimateGroup';
import {AiFillCheckCircle} from 'react-icons/ai';
import {TiStarFullOutline} from 'react-icons/ti';
import MarketingCampaign from './MarketingCampaign';

// eslint-disable-next-line no-unused-vars
const {REACT_APP_BLOB_STORAGE_PROFILE_ENDPOINT} = process.env;

const FollowerPortfolio = () => {
  const match = useMatch('people/followers/:userName/portfolio');
  const userName = match ? match.params.userName : undefined;
  const {user} = useAuthUser();
  const {marketingInstrumentStatistics} = useFollower();
  const {fetchFollowerMarketingInstrumentStatistics} = useFollowerActions();

  const firstName = marketingInstrumentStatistics?.profile?.firstName
    ? marketingInstrumentStatistics.profile.firstName
    : '';
  const lastName = marketingInstrumentStatistics?.profile?.lastName
    ? marketingInstrumentStatistics.profile.lastName
    : '';
  const name = `${firstName} ${lastName}`;
  const image = user?.photoURL
    ? user.photoURL
    : '/assets/images/placeholder.jpg';

  const statistics = marketingInstrumentStatistics?.marketInstrumentStatistics
    ? marketingInstrumentStatistics.marketInstrumentStatistics
    : [];

  const city = marketingInstrumentStatistics?.address?.city
    ? marketingInstrumentStatistics.address.city
    : null;
  const country = marketingInstrumentStatistics?.address?.country
    ? marketingInstrumentStatistics.address.country
    : null;

  const address = city && country ? `${city}, ${country}` : null;

  useEffect(() => {
    if (userName) {
      fetchFollowerMarketingInstrumentStatistics(userName);
    }
  }, []);

  return (
    <>
      <AppPageMetadata title='Follower Portfolio' />
      <AppRowContainer>
        <Col xs={24}>
          <h2>Portfolio</h2>
          <div className={'last-update'}>
            <span className={'last-update__label'}>Last Update: </span>
            <span>{moment().format('MM/DD/YYYY')}</span>
          </div>
        </Col>

        <Col className={'section-col'} xs={24} lg={24}>
          <AppAnimateGroup type='bottom' animateStyle={{height: 'auto'}}>
            <div className='video-call-user-info'>
              <div className='video-call-user'>
                <Avatar src={image} alt='User' />
                <span className='video-call-user-status'>
                  <AiFillCheckCircle />
                </span>
              </div>
              <div className={'video-call-user-details'}>
                <h3>
                  {name} (@{user?.displayName}) <TiStarFullOutline />
                </h3>
                {address && <p>{address}</p>}
              </div>
            </div>
          </AppAnimateGroup>
        </Col>
        {statistics && (
          <Col className={'section-col'} xs={24} lg={24}>
            <MarketingCampaign marketingCampaign={statistics} />
          </Col>
        )}
      </AppRowContainer>
    </>
  );
};

export default FollowerPortfolio;
