import React, {createContext, useContext} from 'react';
import {notification} from 'antd';
import {useIntl} from 'react-intl';

const placement = 'topRight';

type AppNotificationContextType = {
  openInfoNotification: Function;
  openErrorNotification: Function;
  openSuccessNotification: Function;
};

const defaultNotificationContext = {
  openInfoNotification: () => {},
  openErrorNotification: () => {},
  openSuccessNotification: () => {},
};

const AppNotificationContext = createContext<AppNotificationContextType>(
  defaultNotificationContext,
);

export const useAppNotificationContext = () =>
  useContext(AppNotificationContext);

const AppNotificationProvider = ({children}: any) => {
  const [api, contextHolder] = notification.useNotification();
  const {messages} = useIntl();

  const openInfoNotification = (message: string) => {
    api.info({
      message: String(messages['common.info']),
      description: message,
      placement,
    });
  };

  const openErrorNotification = (message: string) => {
    api.error({
      message: String(messages['common.error']),
      description: message,
      placement,
    });
  };

  const openSuccessNotification = (message: string) => {
    api.success({
      message: String(messages['common.success']),
      description: message,
      placement,
    });
  };

  return (
    <AppNotificationContext.Provider
      value={{
        openInfoNotification,
        openErrorNotification,
        openSuccessNotification,
      }}>
      {contextHolder}
      {children}
    </AppNotificationContext.Provider>
  );
};

export default AppNotificationProvider;
