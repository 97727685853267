import React, {useEffect, useState} from 'react';
import './index.style.less';
import {useMatch} from 'react-router-dom';
import AppsContainer from '../../../@crema/core/AppsContainer';
import AppPageMetadata from '../../../@crema/core/AppPageMetadata';
import AppRowContainer from '../../../@crema/core/AppRowContainer';
import {AppLoader} from '../../../@crema';
import {Col} from 'antd';
import {
  useMarketData,
  useMarketDataActions,
} from '../../../utility/hooks/MarketDataHooks';
import MarketDataChart from './MarketDataChart';
import AppCard from '../../../@crema/core/AppCard';
import AppSelect from '../../../@crema/core/AppSelect';

const MarketDataPage = () => {
  const match = useMatch('market-data/:contractId');
  const contractId = match ? match.params.contractId : undefined;
  const {marketData, loading} = useMarketData();
  const {fetchMarketData} = useMarketDataActions();

  const mapToNum = (type) => {
    switch (type) {
      case 'Day':
        return 1;
      case 'Week':
        return 2;
      case 'Month':
        return 3;
      case 'Year':
        return 4;
      default:
        return 1;
    }
  };

  const mapToType = (type) => {
    switch (type) {
      case 1:
        return 'Day';
      case 2:
        return 'Week';
      case 3:
        return 'Month';
      case 4:
        return 'Year';
      default:
        return 'Day';
    }
  };

  const types = marketData.map((data) => data.type);
  const defaultType = types[0] ? mapToType(types[0]) : null;

  const initialData = !defaultType
    ? []
    : marketData.filter(
        (mData) => Number(mData.type) === Number(mapToNum(defaultType)),
      );

  const [data, setData] = useState(
    initialData.length > 0 ? initialData[0]['data'] : [],
  );

  useEffect(() => {
    if (contractId) {
      fetchMarketData(contractId);
    }
  }, []);

  useEffect(() => {
    if (marketData.length > 0) {
      const types = marketData.map((data) => data.type);
      const defaultType = types[0] ? mapToType(types[0]) : null;

      const data = !defaultType
        ? []
        : marketData.filter(
            (mData) => Number(mData.type) === Number(mapToNum(defaultType)),
          );

      setData(data.length > 0 ? data[0]['data'] : []);
    }
  }, [marketData]);

  const handleSelectionType = (type) => {
    const data = marketData.filter(
      (mData) => Number(mData.type) === mapToNum(type),
    );

    setData(data.length > 0 ? data[0]['data'] : []);
  };

  const menus = ['Day', 'Week', 'Month', 'Year'];

  const extra = (
    <AppSelect
      menus={menus}
      defaultValue={defaultType}
      onChange={handleSelectionType}
    />
  );

  return (
    <AppsContainer
      cardStyle={{backgroundColor: 'transparent', boxShadow: 'none'}}
      fullView>
      <AppPageMetadata title='Market Data' />
      <AppRowContainer>
        {!loading && marketData ? (
          <>
            <Col xs={24} md={24} xl={24} xxl={24}>
              <h2>Market Data</h2>
            </Col>
            <Col xs={24} md={24} xl={24} xxl={24}>
              <AppCard
                className={'statistic-annual__chart'}
                title={'Price statistics'}
                extra={extra}>
                <MarketDataChart data={data} />
              </AppCard>
            </Col>
          </>
        ) : (
          <AppLoader />
        )}
      </AppRowContainer>
    </AppsContainer>
  );
};

export default MarketDataPage;
