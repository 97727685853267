import {
  useMarketDataActionsContext,
  useMarketDataContext,
} from '../../services/marketData/MarketDataProvider';

export const useMarketData = () => {
  const {marketData, loading} = useMarketDataContext();
  return {
    marketData,
    loading,
  };
};

export const useMarketDataActions = () => {
  const {fetchMarketData} = useMarketDataActionsContext();
  return {
    fetchMarketData,
  };
};
