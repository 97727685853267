import {IFilter} from '../utility/filter';

export enum FilterOperator {
  // eslint-disable-next-line no-unused-vars
  CONTAINS = 'CONTAINS',
  // eslint-disable-next-line no-unused-vars
  NOT_EQUAL = '!=',
  // eslint-disable-next-line no-unused-vars
  EQUAL = 'EQUAL',
  // eslint-disable-next-line no-unused-vars
  GT = '>',
  // eslint-disable-next-line no-unused-vars
  LT = '<',
  // eslint-disable-next-line no-unused-vars
  GTEQ = '>=',
  // eslint-disable-next-line no-unused-vars
  LTEQ = '<=',
}

export type StringFilterLessThanValue = {
  value: string | number | undefined;
  operator: FilterOperator;
};

export type StringFilterGreatThanValue = {
  value: string | number | undefined;
  operator: FilterOperator;
};

export type StringFilterLessThanAndEqualValue = {
  value: string | number | undefined;
  operator: FilterOperator;
};

export type StringFilterGreatThanAndEqualValue = {
  value: string | number | undefined;
  operator: FilterOperator;
};

export type StringFilterEqualValue = {
  value: string | number | undefined;
  operator: FilterOperator;
};

export type StringFilterContainsValue = {
  value: string | number | undefined;
  operator: FilterOperator;
};

export type StringFilterNotEqualValue = {
  value: string | number | undefined;
  operator: FilterOperator;
};

export type TraderFilter = IFilter & {
  name: StringFilterContainsValue;
  isApproved: StringFilterEqualValue;
  riskLevel: StringFilterEqualValue;
  newLeader: StringFilterEqualValue;
  userId: StringFilterNotEqualValue;
};

export type TraderFilterValues = {
  name: string | undefined;
  isApproved: number | undefined;
  riskLevel: number | undefined;
  newLeader: number | undefined;
};
