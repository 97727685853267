import React from 'react';
import PropTypes from 'prop-types';

const MarketInstrumentValue = ({value}) => {
  return <div style={{color: value > 0 ? 'green' : 'red'}}>{value}%</div>;
};

export default MarketInstrumentValue;

MarketInstrumentValue.propTypes = {
  value: PropTypes.number,
};
