import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import {Avatar} from 'antd';
import {
  StyledMarketingCampaignTable,
  StyledMarketingUserInfo,
  StyledMarketingUserInfoContent,
} from '../index.styled';
import {getMarketInstrumentImgDir} from '../../../../../../utility/utility/common';
import MarketInstrumentValue from './MarketInstrumentValue';

const OrderTable = ({marketingCampaignData}) => {
  const imgDirPath = getMarketInstrumentImgDir();
  const columns = [
    {
      title: `Market Instrument (${marketingCampaignData.length})`,
      dataIndex: 'marketInstrument',
      key: 'marketInstrument',
      render: (item) => (
        <StyledMarketingUserInfo>
          <Avatar
            src={`${imgDirPath}/${item.avatar}`}
            style={{backgroundColor: item.backgroundColor}}
          />
          <StyledMarketingUserInfoContent>
            <h3>{item.title}</h3>
            <p>{item.companyName}</p>
          </StyledMarketingUserInfoContent>
        </StyledMarketingUserInfo>
      ),
    },
    {
      title: 'Investments (%)',
      dataIndex: 'investments',
      key: 'investments',
      render: (value) => <MarketInstrumentValue value={value} />,
    },
    {
      title: 'ProfitLoss (%)',
      dataIndex: 'profitLoss',
      key: 'profitLoss',
      render: (value) => <MarketInstrumentValue value={value} />,
    },
    {
      title: 'Fund (%)',
      dataIndex: 'fund',
      key: 'fund',
      render: (value) => <MarketInstrumentValue value={value} />,
    },
  ];

  return (
    <StyledMarketingCampaignTable
      data={marketingCampaignData}
      columns={columns}
    />
  );
};

export default OrderTable;

OrderTable.defaultProps = {
  marketingCampaignData: [],
};

OrderTable.propTypes = {
  marketingCampaignData: PropTypes.array,
};
