import React, {createContext, useContext, useEffect, useState} from 'react';
import {useOpenNotification} from '../../utility/hooks/NotificationHooks';
import {EVENT_NEW_TS_ORDER, triggerEvent} from '../../utility/events';
import io from 'socket.io-client';
import {useJWTAuth} from '../../@crema/services/auth/jwt-auth/JWTAuthProvider';

const {REACT_APP_SERVER_URL} = process.env;
type AppSocketContextType = {socket: any};

const defaulSocketContext = {socket: null};

const AppSocketContext =
  createContext<AppSocketContextType>(defaulSocketContext);

export const useAppSocketContext = () => useContext(AppSocketContext);

const AppSocketProvider = ({children}: any) => {
  const {user} = useJWTAuth();
  const [socket, setSocket] = useState<any>(null);

  useEffect(() => {
    const newSocket = io(String(REACT_APP_SERVER_URL));
    newSocket.on('connect', () => {
      console.log('Connected to server!');
      console.log('Connected to server!');
      if (user) {
        console.log('join');
        newSocket.emit('join', {userId: user.user.id});
      }
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server!');
    });

    newSocket.on('new_ts_order', (data) => {
      console.log('new_ts_order', data);

      openInfoNotification(data.msg);
      triggerEvent(EVENT_NEW_TS_ORDER);
    });
    setSocket(newSocket);
  }, []);

  useEffect(() => {
    if (user) {
      console.log('join');
      socket?.emit('join', {userId: user.user.id});
    }
  }, [user]);

  const {openInfoNotification} = useOpenNotification();

  return (
    <AppSocketContext.Provider value={{socket}}>{children}</AppSocketContext.Provider>
  );
};

export default AppSocketProvider;
