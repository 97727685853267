import {
  useFollowerActionsContext,
  useFollowerContext,
} from '../../services/follower/FollowerProvider';

export const useFollower = () => {
  const {marketingInstrumentStatistics, loading} = useFollowerContext();
  return {
    marketingInstrumentStatistics,
    loading,
  };
};

export const useFollowerActions = () => {
  const {fetchFollowerMarketingInstrumentStatistics} =
    useFollowerActionsContext();

  return {
    fetchFollowerMarketingInstrumentStatistics,
  };
};
